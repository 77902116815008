#home-content {
  margin: auto;
  width: 100%;
}
#home-navbar {
  width: 100% !important;
  margin: 0;
  padding: 1.2rem 2rem;
}
.login-page, .register-page {
  margin-top: 10rem !important;
}
#contentWrapper {
  margin-top: 0 !important;
  padding: 0;
}
.our-services-menu {
  top: 7px !important;
}

.dropdown-language .btn-flat-info:focus {
  background-color: #14b7c61a !important;
  color: #14b7c6 !important;
}