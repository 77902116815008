// Define breakpoints
$small-screen: 576px;
$medium-screen: 768px;
$large-screen: 992px;
$extra-large-screen: 1200px;
#home-content {
  margin: auto;
  width: 100%;
}
#home-navbar {
  width: 100% !important;
  margin: 0;
  padding: 1.2rem 2rem;
}
.login-page, .register-page {
  margin-top: 10rem !important;
  margin-bottom: 12rem !important;
  /* Small devices (phones, 576px and below) */
  @media only screen and (max-width: $small-screen) {
    margin-top: 2rem !important;
    margin-bottom: 4rem !important;
  }
}