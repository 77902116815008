@import "./bootstrap.scss";
@import "./components.scss";
@import "./bootstrap-extended.scss";
@import "./colors.scss";
@import "./iconfont.scss";
@import "./custom-rtl.scss";

.create-modal-size {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.modal-full {
  max-width: 96%;
  height: 96%;
  margin: auto;
  .modal-content {
    height: 96%;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.tooltip {
  z-index: 1051 !important;
}
.custom-edit-button {
  width: 16px;
  height: 16px;
  border: none;
  padding: 0 !important;
  background-color: transparent;
}

.text-black {
  color: #000 !important;
}

.notistack-SnackbarContainer {
  z-index: 10002 !important;
}